import styled from 'styled-components';

import topoBg from '../../assets/produtos/2023/expressao/Intro.png';
import footerBg from '../../assets/footer-bg.svg';

export const Button = styled.a`
  background: #dc3f28;
  padding: 0.8rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 20px;
  color: #fff;
  border-radius: 30px;
  width: fit-content;
  text-decoration: none;
  margin: 5rem auto 0;
`;

export const MainDescription = styled.div`
  margin: 5rem auto;
  padding: 0 12rem;
  padding-top: 10rem;
  max-width: 100rem;

  @media only screen and (max-width: 600px) {
    padding: 0 2rem;
    line-height: 3rem;
  }

  p {
    font-size: 2rem;
    font-weight: 100;

    span {
      font-weight: bold;
    }
  }
`;

export const Topo = styled.div`
  /* background: url(${topoBg}) no-repeat right top transparent; */
  background: url(${topoBg}) no-repeat right center transparent;
  background-size: 50%;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  height: 100vh;
  min-height: 700px;

  @media only screen and (max-width: 600px) {
    background-image: none;
  }

  a {
    cursor: pointer;
    border-radius: 34px;
    background: #fff;
    border: 1px solid #255522;
    color: #255522;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    margin-top: 30px;
    transition: all 0.2s ease;

    &:hover {
      background: #255522;
      color: #fff;
    }

    span {
      padding: 0 30px;
      font-weight: 500;
      font-size: 20px;
    }
  }

  .esquerdo {
    width: 50%;
    margin-left: 5%;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }

    .whatsapp-button {
      width: fit-content;
      padding: 8px 16px;
      border: 0;
      margin-top: 24px;
      border-radius: 5px;
      text-decoration: none;
      background: #25d366;
      color: #fff;
      display: flex;
      align-items: center;

      svg {
        color: #fff;
      }

      span {
        padding: 0 16px;
        margin: 0;
        background: transparent;
      }
    }
  }

  .centro {
    width: 100%;
    margin-bottom: 25px;
    text-align: center;

    @media only screen and (max-width: 600px) {
      width: 80%;
      margin: 0 auto 25px;
    }

    a {
      margin: 0 auto;
      color: #dc3f28;
      border-color: #dc3f28;

      &:hover {
        background: #dc3f28;
        color: #fff;
      }
    }
  }

  .marcas {
    margin-bottom: 80px;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }

    img {
      margin-right: 40px;
    }
  }

  h1 {
    color: #255522;
    font-size: 60px;
    line-height: 60px;
    font-weight: 300;

    @media only screen and (max-width: 600px) {
      font-size: 40px;
      line-height: 40px;
    }

    strong {
      font-weight: 700;
    }
  }
`;

export const Produtos = styled.div`
  padding-top: 400px;
  margin: 0 auto;
  padding: 0 5%;
  max-width: 100rem;

  @media only screen and (max-width: 1920px) {
    padding-top: 350px;
  }

  @media only screen and (max-width: 1440px) {
    padding-top: 220px;
  }

  @media only screen and (max-width: 600px) {
    padding-top: 100px;
  }

  .produto-item {
    display: flex;
    align-items: center;
    margin-bottom: 230px;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      margin-bottom: 100px;

      &:nth-child(even) {
        flex-direction: column-reverse;
      }
    }

    &:nth-child(even) {
      .descricao {
        margin-right: 15%;
      }
      .textura {
        left: -100px;
      }

      .badge {
        margin-right: -100px;
        margin-top: -300px;
        z-index: 50;
        @media only screen and (max-width: 600px) {
          margin-top: -137px;
        }
      }

      p {
        margin-right: -100px;
        margin-top: -304px;
        @media only screen and (max-width: 600px) {
          margin-top: -137px;
        }
      }
    }
    &:nth-child(odd) {
      .descricao {
        margin-left: 15%;
      }
      .textura {
        right: -100px;
      }

      .badge {
        margin-left: -153px;
        margin-top: -300px;
        @media only screen and (max-width: 600px) {
          margin-left: -100px;
          margin-top: -137px;
        }
      }

      p {
        margin-left: -100px;
        margin-top: -304px;
        @media only screen and (max-width: 600px) {
          margin-left: -100px;
          margin-top: -137px;
        }
      }
    }

    .imagem {
      width: 60%;
      display: flex;
      align-items: center;
      position: relative;

      @media only screen and (max-width: 600px) {
        width: 100%;
      }

      .textura {
        display: flex;
        width: 180px;
        height: 166.8px;
        position: absolute;
        z-index: 50;

        @media only screen and (max-width: 600px) {
          display: none;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        .detail {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-image: linear-gradient(to top, black -50%, transparent);
          opacity: 0;
          transition: all ease 0.5s;
          display: flex;
          align-items: flex-end;
          justify-content: center;

          p {
            margin: 0;
            margin-bottom: 10px;
            width: 100%;
          }
        }

        :hover {
          .detail {
            opacity: 1;
          }
        }
      }

      .badge {
        position: relative;
        width: 120px;
      }

      p {
        z-index: 51;
        color: #fff;
        width: 80px;
        line-height: 17px;
        font-size: 17px;
        text-align: center;

        span {
          font-size: 16px;
          font-weight: bold;
        }
      }

      .movel {
        width: 100%;
      }
    }
    .descricao {
      width: 40%;
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: 600px) {
        width: 100%;
        margin: 0 !important;
      }

      .baixar-textura {
        margin-right: 30px;
        text-decoration: none;
        width: fit-content;
        color: #255522;
        background-color: transparent;
        border: none;
        margin-top: 15px;
        display: flex;
        align-items: center;
        transition: all ease 0.3s;

        &:hover {
          opacity: 0.6;
          transition: all ease 0.3s;
        }

        p {
          font-weight: 500;
          color: inherit;
          background: initial;
          font-size: 1rem;
        }

        svg {
          height: 25px;
          width: auto;
          margin-left: 10px;
        }
      }

      /* a {
        cursor: pointer;
        border-radius: 34px;
        background: #fff;
        border: 1px solid #255522;
        color: #255522;
        padding: 5px;
        display: inline-flex;
        align-items: center;
        margin-top: 30px;
        transition: all 0.2s ease !important;
        width: fit-content;

        &:hover {
          background: #255522;
          color: #fff;
        }

        span {
          padding: 0 30px;
          font-weight: 500;
          color: inherit;
          background: initial;
          font-size: 1rem;
          margin-bottom: initial;
          display: initial;
        }
      } */

      .whatsapp-button {
        width: fit-content;
        padding: 8px 16px;
        margin-top: 16px;
        border-radius: 5px;
        text-decoration: none;
        background: #25d366;
        color: #fff;
        display: flex;
        align-items: center;
        transition: all ease 0.3s;

        &:hover {
          opacity: 0.6;
          transition: all ease 0.3s;
        }

        svg {
          color: #fff;
        }

        span {
          padding: 0 16px;
          margin: 0;
          background: transparent;
        }
      }

      h2 {
        font-weight: bold;
        font-size: 46px;
        color: #464646;
        margin: 0;
      }
      span {
        font-weight: 300;
        font-size: 20px;
        color: #fff;
        border-radius: 23px;
        background: #dc3f28;
        padding: 5px 20px;
        margin-bottom: 30px;
        display: inline-block;

        @media only screen and (max-width: 600px) {
          padding: 5px;
        }
      }
      p {
        font-weight: 300;
        font-size: 20px;
        line-height: 28px;
        color: #000;
        margin: 0;
      }
    }
  }
`;

export const GridProdutos = styled.div`
  padding-bottom: 200px;

  .holder {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 25px;

    @media only screen and (max-width: 600px) {
      grid-template-columns: 50% 50%;
      grid-gap: 14px;
    }

    @media only screen and (max-width: 480px) {
      grid-template-columns: 100%;
    }

    .descricao {
      padding: 30px 0;

      @media only screen and (max-width: 600px) {
        width: 100%;
        margin: 0 !important;
      }

      h2 {
        font-weight: bold;
        font-size: 36px;
        padding: 10px 0;
        color: #464646;
        margin: 0;
      }
      span {
        font-weight: 300;
        font-size: 16px;
        color: #fff;
        border-radius: 23px;
        background: #dc3f28;
        padding: 5px 20px;
        margin-bottom: 30px;
        display: inline-block;

        @media only screen and (max-width: 600px) {
          padding: 5px;
        }
      }
      p {
        font-weight: 300;
        font-size: 16px;
        line-height: 28px;
        color: #000;
        margin: 0;
      }
    }
  }
`;

export const FormRD = styled.div`
  width: 40%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  .whatsapp {
    cursor: pointer;
    border-radius: 34px;
    background: #fff;
    border: 1px solid #255522;
    color: #255522;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    transition: all 0.2s ease !important;
    width: fit-content;
    text-decoration: none;
    margin-bottom: 50px;

    @media only screen and (max-width: 600px) {
      width: calc(100% - 40px);
    }

    &:hover {
      background: #255522;
      color: #fff;
    }

    span {
      padding: 0 30px;
      font-weight: 500;
      color: inherit;
      background: initial;
      font-size: 1.5rem;
      margin-bottom: initial;
      display: initial;
    }

    svg {
      height: 50px;
      width: auto;
      fill: #dc3f28;
    }
  }

  h2 {
    font-family: Ubuntu;
    font-weight: bold;
    font-size: 28px;
    line-height: 37px;
    text-align: center;
    color: #255522;
  }
  p {
    font-weight: normal;
    font-size: 22px;
    line-height: 25px;
    text-align: center;
    color: #464646;
  }
  p:last-of-type {
    color: #255522;
    font-size: 14px;
    width: 554px;
    margin: 0 auto;
    font-weight: bold;
    padding: 0 1px;

    @media only screen and (max-width: 1320px) {
      width: inherit;
    }
  }

  .promotional-text {
    width: 100%;
    @media only screen and (max-width: 1320px) {
      margin-bottom: 120px;
      padding: 0 40px;
    }
  }

  .form {
    width: 100%;
    height: 770px;
    text-align: center;
  }
`;
export const Footer = styled.div`
  background: url(${footerBg}) no-repeat center top #f7f7f7;
  padding: 300px 0 0;

  @media only screen and (max-width: 600px) {
    padding: 0;
  }

  .marcas {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .separador {
    margin: 0 50px;
    width: 1px;
    height: 55px;
    background: #707070;
  }

  .ade {
    text-align: center;
    padding: 30px 0;

    a {
      color: #666;
      text-decoration: none;
      font-size: 14px;

      &:hover {
        border-bottom: 1px solid #666;
      }

      span {
        padding-left: 3px;
      }
    }
  }
`;

export const Whatsapp = styled.section`
  a {
    position: fixed;
    width: fit-content;
    height: fit-content;
    bottom: 50px;
    right: 50px;
    z-index: 5;

    img {
      width: 60px;
      height: 60px;
      transition: all ease 0.5s;
    }

    :hover {
      img {
        transform: scale(1.25);
      }
    }
  }
`;
