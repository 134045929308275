import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-scroll';
import AOS from 'aos';
import { AiOutlineDownload } from 'react-icons/ai';
import { FaWhatsapp } from 'react-icons/fa';

import gmadLogo from '../../assets/gmad-logo.svg';
import duratexLogo from '../../assets/Duratex-COR.png';
import ofertasIco from '../../assets/ofertas-ico.svg';
import produtosIco from '../../assets/produtos-ico.svg';

import YoutubeEmbed from '../../components/YoutubeEmbed';

import {
  Topo,
  Produtos,
  Footer,
  MainDescription,
  Button,
  Whatsapp,
} from './styles';
import 'aos/dist/aos.css';

export default function Home() {
  AOS.init({
    duration: 1500,
  });

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());

  gtag('config', 'AW-922520425');

  function gtag_report_conversion(url) {
    var callback = function () {
      if (typeof url != 'undefined') {
        window.open(url);
      }
    };
    gtag('event', 'conversion', {
      send_to: 'AW-922520425/JyrlCN-76OkCEOmW8rcD',
      event_callback: callback,
    });
    return false;
  }

  // return (
  //   <>
  //     <Helmet>
  //       <title>GMAD e Duratex, muito mais benefícios para você</title>
  //       <meta
  //         name="description"
  //         content="Conheça as linhas de MDF que estão em oferta.
  //         Basta preencher o formulário que um vendedor entrará em contato."
  //       />
  //       <meta property="og:image" content="screenshot.png" />
  //       <meta property="og:image:width" content="960" />
  //       <meta property="og:image:height" content="600" />
  //       <script
  //         async
  //         src="https://www.googletagmanager.com/gtag/js?id=AW-922520425"
  //       ></script>
  //     </Helmet>

  //     <div
  //       style={{
  //         maxWidth: 450,
  //         textAlign: 'center',
  //         margin: '0 auto',
  //         padding: '100px 20px',
  //         background: 'white',
  //       }}
  //     >
  //       <p>
  //         Este site está passando por uma manutenção programada. Pedimos
  //         desculpa pela inconveniência!
  //       </p>
  //     </div>
  //   </>
  // );

  return (
    <>
      <Helmet>
        <title>GMAD e Duratex, muito mais benefícios para você</title>
        <meta
          name="description"
          content="Conheça as linhas de MDF que estão em oferta.
          Basta preencher o formulário que um vendedor entrará em contato."
        />
        <meta property="og:image" content="screenshot.png" />
        <meta property="og:image:width" content="960" />
        <meta property="og:image:height" content="600" />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-922520425"
        ></script>
      </Helmet>
      <Topo>
        <div className="esquerdo">
          <div className="marcas">
            <img src={gmadLogo} alt="GMAD" data-aos="fade-down" />
            <img
              className="duratex"
              src={duratexLogo}
              alt="DURATEX"
              data-aos="fade-down"
              data-aos-delay="100"
            />
          </div>
          <h1 data-aos="fade-zoom-in" data-aos-delay="600">
            <strong>
              GMAD e
              <br />
              Duratex,
            </strong>{' '}
            muito <br />
            mais benefícios <br /> para você!
          </h1>
          {/* <a
            href="https://bit.ly/adewhatsduratex1"
            target="_blank"
            className="whatsapp-button"
            rel="noopener noreferrer"
          >
            <FaWhatsapp size={30} />
            <span>Quero receber meu catálogo</span>{' '}
            // <img src={ofertasIco} width="46" alt="Ofertas" />
          </a> */}
        </div>

        <div className="centro">
          <Link
            to="main_decription"
            smooth={true}
            data-aos="zoom-in"
            data-aos-delay="1300"
            data-aos-offset="0"
          >
            <span>
              Veja abaixo os <strong>produtos selecionados</strong>
            </span>{' '}
            <img src={produtosIco} width="46" alt="Produtos" />
          </Link>
        </div>
      </Topo>
      <MainDescription id="main_decription">
        <p className="description-main" data-aos="fade-up" data-aos-delay="300">
          A GMAD e a Duratex se uniram para trazer benefícios exclusivos para
          você! Conheça os principais lançamentos e aproveite esta{' '}
          <span>oportunidade.</span>
        </p>
      </MainDescription>

      {/* <YoutubeEmbed youtubeUrl="RmlfZ12fJz0" /> */}

      <Button
        target="__blank"
        rel="noopener noreferrer"
        href="/Catálogo-Expressao.pdf"
      >
        Baixe nosso catálogo
      </Button>

      <Produtos id="produtos">
        <div className="produto-item">
          <div className="imagem">
            <img
              className="movel"
              src={require('../../assets/produtos/2023/expressao/Cinza-Fossil.png')}
              alt="Renda"
              data-aos="fade-up"
              data-aos-delay="300"
            />
            <a
              href={require('../../assets/produtos/2023/expressao/texturas/cinza-fossil-textura.png')}
              className="textura"
              download
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="detail">
                <p>Baixar textura</p>
              </div>
              <img
                src={require('../../assets/produtos/2023/expressao/texturas/cinza-fossil-textura.png')}
                alt="Renda"
              />
            </a>
          </div>

          <div className="descricao" data-aos="fade-up" data-aos-delay="500">
            <h2>Cinza Fóssil</h2>
            <span>Linha Velluto</span>
            <p>
              Cor neutra com um fundo aquecido, acolhe ao mesmo tempo que
              evoca elegância. Fácil de combinar, podendo ser o grande destaque,
              como o detalhe que complementa.
            </p>
            <a
              href={require('../../assets/produtos/2023/expressao/texturas/cinza-fossil-textura.png')}
              download
              target="_blank"
              rel="noopener noreferrer"
              className="baixar-textura"
            >
              <p>Baixar textura</p>
              <AiOutlineDownload />
            </a>
            <a
              // href="https://bit.ly/adewhatsduratex1"
              onClick={(e) =>
                gtag_report_conversion('https://bit.ly/adewhatsduratex1')
              }
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp-button"
            >
              <FaWhatsapp size={30} />
              <span>Quero saber mais</span>{' '}
              {/* <img src={ofertasIco} width="46" alt="Ofertas" /> */}
            </a>
          </div>
        </div>

        {/* Gianduia Natural */}
        <div className="produto-item">
          <div className="descricao" data-aos="fade-up" data-aos-delay="500">
            <h2>Gianduia Natural</h2>
            <span>Linha Velluto</span>
            <p>
              Da alquimia de sabores, um sentir
              refinado, leve e de originalidade.
              Tonalidade versátil, que acolhe e
              aquece as combinações.
            </p>
            <a
              href={require('../../assets/produtos//2023/expressao/texturas/Gianduia-Natural-textura.png')}
              download
              target="_blank"
              rel="noopener noreferrer"
              className="baixar-textura"
            >
              <p>Baixar textura</p>
              <AiOutlineDownload />
            </a>
            <a
              // href="https://bit.ly/adewhatsduratex1"
              onClick={(e) =>
                gtag_report_conversion('https://bit.ly/adewhatsduratex1')
              }
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp-button"
            >
              <FaWhatsapp size={30} />
              <span>Quero saber mais</span>{' '}
              <img src={ofertasIco} width="46" alt="Ofertas" />
            </a>
          </div>
          <div className="imagem">
            <img
              className="movel"
              src={require('../../assets/produtos/2023/expressao/GianduiaNatural.png')}
              alt="Fibra Nativa Móvel"
              data-aos="fade-up"
              data-aos-delay="300"
            />
            <a
              href={require('../../assets/produtos//2023/expressao/texturas/Gianduia-Natural-textura.png')}
              className="textura"
              download
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="detail">
                <p>Baixar textura</p>
              </div>
              <img
                src={require('../../assets/produtos//2023/expressao/texturas/Gianduia-Natural-textura.png')}
                alt="Fibra Nativa"
              />
            </a>
          </div>
        </div>

        {/* Quartzo Bienna */}
        <div className="produto-item">
          <div className="imagem">
            <a
              href={require('../../assets/produtos/2023/expressao/texturas/Quartzo-biena-textura.png')}
              className="textura"
              download
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="detail">
                <p>Baixar textura</p>
              </div>
              <img
                src={require('../../assets/produtos/2023/expressao/texturas/Quartzo-biena-textura.png')}
                alt="Ouro Pálido"
              />
            </a>

            <img
              className="movel"
              src={require('../../assets/produtos/2023/expressao/Quartzo-Bienna.png')}
              alt="Ouro Pálido"
              data-aos="fade-up"
              data-aos-delay="500"
            />
          </div>
          <div className="descricao" data-aos="fade-up" data-aos-delay="500">
            <h2>Quartzo Bienna</h2>
            <span>Linha Velluto</span>
            <p>
              Cores, veios e desenho que surpreendem pela naturalidade,
              possibilita projetos autênticos e com personalidade.
            </p>
            <a
              href={require('../../assets/produtos/2023/expressao/texturas/Quartzo-biena-textura.png')}
              download
              target="_blank"
              rel="noopener noreferrer"
              className="baixar-textura"
            >
              <p>Baixar textura</p>
              <AiOutlineDownload />
            </a>
            <a
              // href="https://bit.ly/adewhatsduratex1"
              onClick={(e) =>
                gtag_report_conversion('https://bit.ly/adewhatsduratex1')
              }
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp-button"
            >
              <FaWhatsapp size={30} />
              <span>Quero conhecer esta linha</span>{' '}
              <img src={ofertasIco} width="46" alt="Ofertas" />
            </a>
          </div>
        </div>

        {/* Nazca */}
        <div className="produto-item">
          <div className="descricao" data-aos="fade-up" data-aos-delay="500">
            <h2>Nazca</h2>
            <span>Linha Velluto</span>
            <p>
              Com um delicado padrão em losango e uma cor suave, sua
              textura transita entre o metalizado e o hibrido, adicionando
              informação de forma sofisticada, criando um visual único.
            </p>
            <a
              href={require('../../assets/produtos/2023/expressao/texturas/Nazca-textura.png')}
              download
              target="_blank"
              rel="noopener noreferrer"
              className="baixar-textura"
            >
              <p>Baixar textura</p>
              <AiOutlineDownload />
            </a>
            <a
              // href="https://bit.ly/adewhatsduratex1"
              onClick={(e) =>
                gtag_report_conversion('https://bit.ly/adewhatsduratex1')
              }
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp-button"
            >
              <FaWhatsapp size={30} />
              <span>Quero saber mais</span>{' '}
              <img src={ofertasIco} width="46" alt="Ofertas" />
            </a>
          </div>
          <div className="imagem">
            <img
              className="movel"
              src={require('../../assets/produtos/2023/expressao/Nazca.png')}
              alt="Sépia"
              data-aos="fade-up"
              data-aos-delay="300"
            />
            <a
              href={require('../../assets/produtos/2023/expressao/texturas/Nazca-textura.png')}
              className="textura"
              download
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="detail">
                <p>Baixar textura</p>
              </div>
              <img
                src={require('../../assets/produtos/2023/expressao/texturas/Nazca-textura.png')}
                alt="Sépia"
              />
            </a>
          </div>
        </div>

        {/* Moss */}
        <div className="produto-item">
          <div className="imagem">
            <a
              href={require('../../assets/produtos/2023/expressao/texturas/Moss-textura.png')}
              className="textura"
              download
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="detail">
                <p>Baixar textura</p>
              </div>
              <img
                src={require('../../assets/produtos/2023/expressao/texturas/Moss-textura.png')}
                alt="Jequitibá Rosa"
              />
            </a>

            <img
              className="movel"
              src={require('../../assets/produtos/2023/expressao/Moss.png')}
              alt="Jequitibá Rosa"
              data-aos="fade-up"
              data-aos-delay="500"
            />
          </div>
          <div className="descricao" data-aos="fade-up" data-aos-delay="500">
            <h2>Moss</h2>
            <span>Linha Velluto</span>
            <p>
              Revestimento natural, de toque aveludado que traz positividade
              e harmonia. Das tonalidades da natureza, uma frequência calma
              que traz esperança e elegância.
            </p>
            <a
              href={require('../../assets/produtos/2023/expressao/texturas/Moss-textura.png')}
              download
              target="_blank"
              rel="noopener noreferrer"
              className="baixar-textura"
            >
              <p>Baixar textura</p>
              <AiOutlineDownload />
            </a>
            <a
              // href="https://bit.ly/adewhatsduratex1"
              onClick={(e) =>
                gtag_report_conversion('https://bit.ly/adewhatsduratex1')
              }
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp-button"
            >
              <FaWhatsapp size={30} />
              <span>Quero conhecer esta linha</span>{' '}
              <img src={ofertasIco} width="46" alt="Ofertas" />
            </a>
          </div>
        </div>

        {/* Nogueira Flórida */}
        <div className="produto-item">
          <div className="descricao" data-aos="fade-up" data-aos-delay="500">
            <h2>Nogueira Flórida</h2>
            <span>Linha Design</span>
            <p>
              Com desenhos de catedrais de aspecto mais rústicos e
              tonalidade de madeira moderna, deixa os ambientes com
              uma atmosfera mais convidativa e tranquila.
            </p>
            <a
              href={require('../../assets/produtos/2023/expressao/texturas/Nogueira-florida-textura.png')}
              download
              target="_blank"
              rel="noopener noreferrer"
              className="baixar-textura"
            >
              <p>Baixar textura</p>
              <AiOutlineDownload />
            </a>
            <a
              // href="https://bit.ly/adewhatsduratex1"
              onClick={(e) =>
                gtag_report_conversion('https://bit.ly/adewhatsduratex1')
              }
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp-button"
            >
              <FaWhatsapp size={30} />
              <span>Quero saber mais</span>{' '}
              <img src={ofertasIco} width="46" alt="Ofertas" />
            </a>
          </div>
          <div className="imagem">
            <img
              className="movel"
              src={require('../../assets/produtos/2023/expressao/NogueiraFlorida.png')}
              alt="Pau Ferro Natural"
              data-aos="fade-up"
              data-aos-delay="300"
            />
            <a
              href={require('../../assets/produtos/2023/expressao/texturas/Nogueira-florida-textura.png')}
              className="textura"
              download
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="detail">
                <p>Baixar textura</p>
              </div>
              <img
                src={require('../../assets/produtos/2023/expressao/texturas/Nogueira-florida-textura.png')}
                alt="Pau Ferro Natural"
              />
            </a>
          </div>
        </div>

        {/* Branco Diamante */}
        <div className="produto-item">
          <div className="imagem">
            <a
              href={require('../../assets/produtos/2023/expressao/texturas/Branco-diamante-textura.png')}
              className="textura"
              download
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="detail">
                <p>Baixar textura</p>
              </div>
              <img
                src={require('../../assets/produtos/2023/expressao/texturas/Branco-diamante-textura.png')}
                alt="Absoluto"
              />
            </a>

            <img
              className="movel"
              src={require('../../assets/produtos/2023/expressao/branco_diamante.jpg')}
              alt="Absoluto"
              data-aos="fade-up"
              data-aos-delay="500"
            />
          </div>
          <div className="descricao" data-aos="fade-up" data-aos-delay="500">
            <h2>Branco Diamante</h2>
            <span>Linha Acetinatta
            </span>
            <p>
              Branco puro, tom iluminador que traz calma e concentração.
              A leveza do tom abre espaço, traz paz, limpa, também amplia
              nosso olhar para os elementos do entorno a ele.
            </p>
            <a
              href={require('../../assets/produtos/2023/expressao/texturas/Branco-diamante-textura.png')}
              download
              target="_blank"
              rel="noopener noreferrer"
              className="baixar-textura"
            >
              <p>Baixar textura</p>
              <AiOutlineDownload />
            </a>
            <a
              // href="https://bit.ly/adewhatsduratex1"
              onClick={(e) =>
                gtag_report_conversion('https://bit.ly/adewhatsduratex1')
              }
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp-button"
            >
              <FaWhatsapp size={30} />
              <span>Quero conhecer esta linha</span>{' '}
              <img src={ofertasIco} width="46" alt="Ofertas" />
            </a>
          </div>
        </div>

        {/* Tartufo */}
        <div className="produto-item">
          <div className="descricao" data-aos="fade-up" data-aos-delay="500">
            <h2>Tartufo</h2>
            <span>Linha Acetinatta</span>
            <p>
              Aromas terrosos, ares de natureza, frutos secos. Sua
              tonalidade acolhe e é um deleite aos apreciadores de
              tons aquecidos e aconchegantes.
            </p>
            <a
              href={require('../../assets/produtos/2023/expressao/texturas/Tartufo-textura.png')}
              download
              target="_blank"
              rel="noopener noreferrer"
              className="baixar-textura"
            >
              <p>Baixar textura</p>
              <AiOutlineDownload />
            </a>
            <a
              // href="https://bit.ly/adewhatsduratex1"
              onClick={(e) =>
                gtag_report_conversion('https://bit.ly/adewhatsduratex1')
              }
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp-button"
            >
              <FaWhatsapp size={30} />
              <span>Quero saber mais</span>{' '}
              <img src={ofertasIco} width="46" alt="Ofertas" />
            </a>
          </div>
          <div className="imagem">
            <img
              className="movel"
              src={require('../../assets/produtos/2023/expressao/tartufo.jpg')}
              alt="Basalto"
              data-aos="fade-up"
              data-aos-delay="300"
            />
            <a
              href={require('../../assets/produtos/2023/expressao/texturas/Tartufo-textura.png')}
              className="textura"
              download
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="detail">
                <p>Baixar textura</p>
              </div>
              <img
                src={require('../../assets/produtos/2023/expressao/texturas/Tartufo-textura.png')}
                alt="Basalto"
              />
            </a>
          </div>
        </div>

        {/* Grafite*/}
        <div className="produto-item">
          <div className="imagem">
            <a
              href={require('../../assets/produtos/2023/expressao/texturas/Grafite-textura.png')}
              className="textura"
              download
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="detail">
                <p>Baixar textura</p>
              </div>
              <img
                src={require('../../assets/produtos/2023/expressao/texturas/Grafite-textura.png')}
                alt="Absoluto"
              />
            </a>

            <img
              className="movel"
              src={require('../../assets/produtos/2023/expressao/grafite.jpg')}
              alt="Absoluto"
              data-aos="fade-up"
              data-aos-delay="500"
            />
          </div>
          <div className="descricao" data-aos="fade-up" data-aos-delay="500">
            <h2>Grafite</h2>
            <span>Linha Acetinatta
            </span>
            <p>
              Material natural a partir do carbono, de tonalidade profunda
              e neutra. Mineral cinza escuro que traz versatilidade para as
              combinações.
            </p>
            <a
              href={require('../../assets/produtos/2023/expressao/texturas/Grafite-textura.png')}
              download
              target="_blank"
              rel="noopener noreferrer"
              className="baixar-textura"
            >
              <p>Baixar textura</p>
              <AiOutlineDownload />
            </a>
            <a
              // href="https://bit.ly/adewhatsduratex1"
              onClick={(e) =>
                gtag_report_conversion('https://bit.ly/adewhatsduratex1')
              }
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp-button"
            >
              <FaWhatsapp size={30} />
              <span>Quero conhecer esta linha</span>{' '}
              <img src={ofertasIco} width="46" alt="Ofertas" />
            </a>
          </div>
        </div>
        {/* Preto */}
        <div className="produto-item">
          <div className="descricao" data-aos="fade-up" data-aos-delay="500">
            <h2>Preto</h2>
            <span>Linha Acetinatta</span>
            <p>
              Tonalidade neutra mas poderosa,
              traz sensação de força e proteção.
              O preto é sempre marcante e traz
              elegância e personalidade para as
              combinações.
            </p>
            <a
              href={require('../../assets/produtos/2023/expressao/texturas/Preto-textura.png')}
              download
              target="_blank"
              rel="noopener noreferrer"
              className="baixar-textura"
            >
              <p>Baixar textura</p>
              <AiOutlineDownload />
            </a>
            <a
              // href="https://bit.ly/adewhatsduratex1"
              onClick={(e) =>
                gtag_report_conversion('https://bit.ly/adewhatsduratex1')
              }
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp-button"
            >
              <FaWhatsapp size={30} />
              <span>Quero saber mais</span>{' '}
              <img src={ofertasIco} width="46" alt="Ofertas" />
            </a>
          </div>
          <div className="imagem">
            <img
              className="movel"
              src={require('../../assets/produtos/2023/expressao/preto.jpg')}
              alt="Basalto"
              data-aos="fade-up"
              data-aos-delay="300"
            />
            <a
              href={require('../../assets/produtos/2023/expressao/texturas/Preto-textura.png')}
              className="textura"
              download
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="detail">
                <p>Baixar textura</p>
              </div>
              <img
                src={require('../../assets/produtos/2023/expressao/texturas/Preto-textura.png')}
                alt="Basalto"
              />
            </a>
          </div>
        </div>

        {/* <GridProdutos>
          <div className="holder">
            <div className="block-content">
              <img
                className="movel"
                src={require('../../assets/produtos/grid-produtos/renda.jpg')}
                alt="Renda "
              />
              <div className="descricao">
                <h2>Renda </h2>
                // <span>Coleção Olhares</span>
                <p>
                  Sensível, o padrão Renda consegue transmitir a sensação viva
                  de acolhimento através de sua textura próxima às das tramas de
                  um tecido. Sua tonalidade bege é delicada, com uma elegância
                  essencial para ambientes internos.
                </p>
              </div>
            </div>
            <div className="block-content">
              <img
                className="movel"
                src={require('../../assets/produtos/grid-produtos/fibra-nativa.jpg')}
                alt="Fibra Nativa"
              />
              <div className="descricao">
                <h2>Fibra Nativa</h2>
                // <span>Coleção Olhares</span>
                <p>
                  Fibra Nativa carrega a forma das rendas orgânicas. Suas linhas
                  de cores contrastantes se interconectam formando padrões
                  quadráticos. Atemporal e nostálgico, este padrão consegue nos
                  transportar para ambientes mais simples e, ao mesmo tempo,
                  ricos.
                </p>
              </div>
            </div>
            <div className="block-content">
              <img
                className="movel"
                src={require('../../assets/produtos/grid-produtos/ouro-palido.png')}
                alt="Ouro Pálido"
              />
              <div className="descricao">
                <h2>Ouro Pálido</h2>
                <span>Coleção Olhares</span>
                <p>
                  Ouro Pálido, como o próprio nome nos apresenta, tem um leve
                  tom dourado marcante que combina perfeitamente com o interior
                  das residências mais sofisticadas. Sua textura, delicada e
                  marcante, é seu principal ponto de destaque e luminância.
                </p>
              </div>
            </div>
            <div className="block-content">
              <img
                className="movel"
                src={require('../../assets/produtos/grid-produtos/sepia.jpg')}
                alt="Sépia"
              />
              <div className="descricao">
                <h2>Sépia</h2>
                <span>Coleção Olhares</span>
                <p>
                  Sépia surgiu do desejo de iluminar. Sua textura enriquecida
                  combina perfeitamente com os tons metalizados pálidos.
                  Sofisticado, tem o poder de acolher e abraçar, compondo
                  ambientes nostálgicos que nos transportam no tempo.
                </p>
              </div>
            </div>
            <div className="block-content">
              <img
                className="movel"
                src={require('../../assets/produtos/grid-produtos/jequitiba-rosa.jpg')}
                alt="Jequitibá Rosa"
              />
              <div className="descricao">
                <h2>Jequitibá Rosa</h2>
                <span>Coleção Olhares</span>
                <p>
                  Jequitibá Rosa emula a textura natural do Jequitibá. Suas
                  linhas contrastantes imitam os veios da madeira, sendo
                  enriquecido pelo subtom leve de rosa, o qual se torna ainda
                  mais evidente com a incidência de luz natural.
                </p>
              </div>
            </div>
            <div className="block-content">
              <img
                className="movel"
                src={require('../../assets/produtos/grid-produtos/pau-ferro-natural.jpg')}
                alt="Pau Ferro Natural"
              />
              <div className="descricao">
                <h2>Pau Ferro Natural</h2>
                <span>Coleção Olhares</span>
                <p>
                  Pau Ferro Natural é uma madeira nativa do Brasil. Suas linhas
                  bem marcadas foram a marca da sofisticação na decoração de
                  ambientes nos últimos meses, sendo tendência das últimas
                  estações. Sua tonalidade amarronzada é reconhecidamente
                  versátil, combinando com outros padrões facilmente.
                </p>
              </div>
            </div>
            <div className="block-content">
              <img
                className="movel"
                src={require('../../assets/produtos/grid-produtos/absoluto.jpg')}
                alt="Absoluto"
              />
              <div className="descricao">
                <h2>Absoluto</h2>
                <span>Coleção Olhares</span>
                <p>
                  Absoluto recebe este nome por ser um dos padrões mais
                  marcantes e sofisticados da Coleção Olhares. Sua textura
                  inesquecível imita os veios e os nós das madeiras naturais e
                  sua tonalidade escura e rica nos lembra o ébano do mais alto
                  padrão.
                </p>
              </div>
            </div>
            <div className="block-content">
              <img
                className="movel"
                src={require('../../assets/produtos/grid-produtos/basalto.jpg')}
                alt="Basalto"
              />
              <div className="descricao">
                <h2>Basalto</h2>
                <span>Coleção Olhares</span>
                <p>
                  Basalto constitui uma das rochas mais abundantes em terras
                  brasileiras. Com linhas fluidas e beges que riscam a
                  tonalidade leitosa e calmante do cinza, Basalto é ideal para
                  ambientes internos que pedem por sofisticação.
                </p>
              </div>
            </div>
            <div className="block-content">
              <img
                className="movel"
                src={require('../../assets/produtos/grid-produtos/rosa-infinito.jpg')}
                alt="Rosa Infinito"
              />
              <div className="descricao">
                <h2>Rosa Infinito</h2>
                <span>Coleção Olhares</span>
                <p>
                  Rosa Infinito foi pensando em especial para aquelas pessoas
                  que amam decoração e acreditam que simplicidade e sofisticação
                  andam juntos. Sua tonalidade natural de rosa é marcada pelo
                  subtom quente que eleva sua imagem ao aconchego.
                </p>
              </div>
            </div>
            <div className="block-content">
              <img
                className="movel"
                src={require('../../assets/produtos/grid-produtos/pinole.png')}
                alt="Pinole"
              />
              <div className="descricao">
                <h2>Pinole</h2>
                <span>Coleção Olhares</span>
                <p>
                  Os olhares mais atentos conseguem encontrar Pinole na
                  natureza, entre as cascas das árvores e no chão de terra rica.
                  Este padrão calmante abraça e marca nossa memória com sua
                  tonalidade vívida de marrom alaranjado. Com um subtom quase
                  dourado, Pinole combina sofisticação e aconchego com maestria.
                </p>
              </div>
            </div>
          </div>
        </GridProdutos> */}
      </Produtos>
      {/* <FormRD id="form-rd" data-aos="fade-up" data-aos-delay="300">
        <a
          href="https://bit.ly/adewhatsduratex1"
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp"
        >
          <span>Fale com um de nossos vendedores</span> <FaWhatsapp />
        </a>
        <h2>ou entre em contato conosco</h2> */}
      {/* <p>Basta preencher o formulário abaixo.</p> */}

      {/* <iframe
          title="RD Station"
          className="form"
          src="https://forms.rdstation.com.br/landing-page-gmad-e-duratex-6e6c760a9d02b707bb4b"
          frameBorder="0"
        ></iframe>
      </FormRD> */}
      <Whatsapp>
        <a
          // href="https://bit.ly/adewhatsduratex1"
          onClick={(e) =>
            gtag_report_conversion('https://bit.ly/adewhatsduratex1')
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('../../assets/whatsapp.png')} alt="Whatsapp" />
        </a>
      </Whatsapp>
      <Footer>
        <div className="marcas">
          <a
            href="https://gmad.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={gmadLogo} alt="GMAD" />
          </a>
          <div className="separador">{''}</div>
          <a
            href="https://www.duratexmadeira.com.br/"
            target="_blank"
            rel="noopener noreferrer"
            className="duratex"
          >
            <img src={duratexLogo} alt="DURATEX" />
          </a>
        </div>
        <div className="ade">
          <a
            href="https://agenciaade.com.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            feito com
            <span role="img" aria-label="emoji">
              ♥️
            </span>
            por agencia<strong>ade</strong>.com.br
          </a>
        </div>
      </Footer>
    </>
  );
}
