/* eslint-disable import/first */
import { hot } from 'react-hot-loader/root';
import React from 'react';
import { Router } from 'react-router-dom';

import Routes from './routes';
import history from './services/history';

import GlobalStyle from './styles/global';

import { initGA } from './utils/googleAnalytics';
initGA();

function App() {
  return (
    <Router history={history}>
      <Routes />
      <GlobalStyle />
    </Router>
  );
}

export default hot(App);
