import { createGlobalStyle } from 'styled-components';
import 'antd/dist/antd.css';

import bg from '../assets/bg.svg';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Ubuntu:300,500,700&display=swap');

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Ubuntu', sans-serif;
  }

  html,
  body,
  #root {
    height: 100%;
  }

  *:focus {
    outline: 0;
  }

  html {
    overflow-x: hidden;
  }

  #root {
    /* background: url(${bg}) no-repeat center 0 #f7f7f7; */
    background-size: 100%;
    height: auto;
  }


  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .duratex {
    max-width: 195px;
  }

`;
